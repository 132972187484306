<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-title">
          <div class="card-header card-header-with-btn bg-light">
            <div class="card-title fs25">SEO Meta Data</div>
            <button
              v-if="isAuthorized(permissions.seo_create)"
              class="btn btn-sm btn-primary p-2 btn-resize-standard"
              @click="handleShowHideAction"
            >
              <i class="icon-add"/>
              Add New
            </button>
          </div>
        </div>
        <div class="card-body" v-loading="state.loading">

          <item-table
            :data="state.data"
            :columns="state.columnMap"
            :show-view="false"
            :show-actions="isAuthorized(permissionsGroup.SeoMeta)"
            :show-edit="isAuthorized(permissions.seo_update)"
            :show-delete="isAuthorized(permissions.seo_delete)"
            @[LIST_ITEM_ACTION_DELETE]="handleDataAction(LIST_ITEM_ACTION_DELETE, $event)"
            @[LIST_ITEM_ACTION_UPDATE]="handleDataAction(LIST_ITEM_ACTION_UPDATE, $event)"
          >

            <template #action="{ item }">
              <EditButton
                v-if="isAuthorized(permissions.seo_update)"
                @click="handleDataAction(item, LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.seo_delete)"
                @click="handleDataAction(item, LIST_ITEM_ACTION_DELETE)"
              />
            </template>

            <template #thumbnail="{item}">
              <div class="">
                <img
                  class="img-thumbnail img-48"
                  :src="getImage(item.thumbnail, 'seo')"
                  :alt="`SEO image for ${item.link}`"
                />
              </div>
            </template>

            <template #keywords="{item}">
              <div class="d-flex flex-column gap-2">
                <span
                  class="badge badge-dark"
                  v-for="(keyword, index) in item.keywords"
                  :key="`keyword-${index}`"
                >
                  {{ keyword }}
                </span>
              </div>
            </template>

          </item-table>

        </div>
      </div>
    </div>

    <SeoMetaAction
      v-model="state.showAction"
      :item-to-update="state.selectedItem"
      @updated="handleUpdateAction(true)"
      @hidden="handleUpdateAction(false)"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import ItemTable from '@/components/Util/ItemTable';
import { currency, msgBox } from '@/Mixins/appHelper';
import { LIST_ITEM_ACTION_DELETE, LIST_ITEM_ACTION_UPDATE } from '@/utils/constants';
import SeoMetaAction from '@/components/SeoMeta/SeoMetaAction';
import { getImage, isAuthorized } from '@/utils/Helper';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default defineComponent({

  name: 'SeoMeta',
  components: { DeleteButton, EditButton, SeoMetaAction, ItemTable },

  setup () {
    const state = reactive({
      showAction: false,
      columnMap: [

        { field: 'link', header: 'Link', sortable: false },
        { field: 'title', header: 'Title' },
        { field: 'keywords', header: 'Keywords', sortable: false },
        { field: 'description', header: 'Description', sortable: false },
        { field: 'copyright', header: 'Copyright', sortable: false },
        { field: 'thumbnail', header: 'Thumbnail', sortable: false },
        { field: 'charset', header: 'Charset', sortable: false },

      ],

      data: computed(() => store.getters['seo/seoMetas']),
      loading: computed(() => store?.state?.seo?.loading),

      selectedItem: {},

    });

    const handleDataAction = (data, action) => {

      if (!isAuthorized(permissionsGroup.SeoMeta)) return;

      state.selectedItem = data;

      if (action === LIST_ITEM_ACTION_DELETE) {

        if (!isAuthorized(permissions.seo_delete)) return;

        SweetAlert.confirmError({
          text: 'This will permanently Delete this SEO meta',
          preConfirm: handleDeleteData
        });

        return;
      }

      if (action === LIST_ITEM_ACTION_UPDATE) {
        if (!isAuthorized(permissions.seo_delete)) return;
        state.showAction = true;
      }

    };

    const handleDeleteData = async () => {

      try {
        let response = await store.dispatch('seo/deleteSeoMeta', state.selectedItem.id);

        Toaster.successAlt({
          message: response.data.message || 'SEO Listing successfully removed'
        });

        // clear selected item
        state.selectedItem = {};

      } catch (e) {
        Toaster.error({
          message: e.message || 'Something went wrong. try again'
        });
      }
    };
    const store = useStore();

    const handleShowHideAction = () => {
      state.showAction = !state.showAction;
    };

    const handleUpdateAction = (updated) => {
      state.selectedItem = {};

      if (updated) {
        getData();
      }
    };

    const getData = async () => {
      const stateData = store.getters['seo/seoMetas'];

      if (stateData.length) return;

      await store.dispatch('seo/getSeoMeta');
    };

    onMounted(async () => {
      await getData();
    });

    return {
      state,
      handleShowHideAction,
      currency,
      handleDataAction,
      LIST_ITEM_ACTION_UPDATE,
      LIST_ITEM_ACTION_DELETE,
      handleUpdateAction,
      getImage,
      isAuthorized,
      permissions,
      permissionsGroup
    };
  },
});
</script>
