<template>
  <modal-comp
    :title-text="`${state.isUpdating ? 'Update' : 'Create'} Seo Meta`"
    :mode="state.isUpdating ? 'update' : 'create'"
    :show="state.showAction"
    @hide="fireEvent('update:modelValue', false)"
    @submit="handleDataSave"
    :loading="state.isFormSubmitting"
    title-icon-class="me-1 icon-globe"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <!--page link-->
          <div class="form-group col-12">
            <label class="col-form-label">
              Link:
              <span class="text-danger">*</span>
              <form-input-help>Page url excluding domain name</form-input-help>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Page Link"
              v-model.trim="state.form.link.value"
              autocomplete="off"
              required
            >
            <form-error-msg :error="state.form.link.error"/>
          </div>

          <!--title-->
          <div class="form-group col-12">
            <label class="col-form-label">
              Title:
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Page Title"
              v-model.trim="state.form.title.value"
              autocomplete="off"
              required
            >
            <form-error-msg :error="state.form.title.error"/>
          </div>

          <!--keywords-->
          <div class="form-group col-12">
            <label class="col-form-label">
              Keywords:
              <span class="text-danger">*</span>
              <form-input-help>Press comma (,) or press Enter button to add new tag</form-input-help>
            </label>
            <div class="d-flex gap-2 flex-wrap">
              <el-tag
                v-for="tag in state.form.keywords.value"
                :key="tag"
                closable
                :disable-transitions="false"
                @close="handleDeleteTag(tag)"
              >
                {{ tag }}
              </el-tag>
              <input
                type="text"
                ref="saveTagInput"
                v-model="state.tagInputField"
                class="form-control"
                placeholder="Type keyword"
                size="mini"
                @keyup="handleAddTag($event)"
              >
            </div>
            <form-error-msg :error="state.form.keywords.error"/>
          </div>

          <!--description-->
          <div class="form-group col-12">
            <label class="col-form-label">
              Description:
              <span class="text-danger">*</span>
            </label>
            <textarea
              class="form-control"
              placeholder="Meta Description"
              v-model.trim="state.form.description.value"
              cols="30"
              rows="2"
              required
            ></textarea>
            <form-error-msg :error="state.form.description.error"/>
          </div>

          <!--          copyright-->
          <div class="form-group col-12">
            <label class="col-form-label">
              Copyright:
            </label>
            <textarea
              class="form-control"
              placeholder="Copyright Text"
              v-model.trim="state.form.copyright.value"
              cols="30"
              rows="2"
              required
            ></textarea>
            <form-error-msg :error="state.form.copyright.error"/>
          </div>

          <!--thumbnail-->
          <div class="form-group col-12">
            <label class="col-form-label">
              Thumbnail:
            </label>
            <div class="custom-file">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="upload Image"
                  :value="state.form.thumbnail.name"
                  readonly
                  @click="triggerClick('uploadThumbnail')"
                >
                <form-error-msg :error="state.form.thumbnail.error"/>
                <div class="input-group-append">
                  <button
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    @click="triggerClick('uploadThumbnail')"
                  >
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      class="d-none"
                      ref="uploadThumbnail"
                      @change="handleFileUpload( $event )"
                    >
                    Choose Image
                  </button>
                </div>
              </div>
              <div v-if="!!state.form.thumbnail.preview">
                <div class="d-flex justify-content-center mt-2">
                  <img
                    class="img-preview"
                    :src="state.form.thumbnail.preview"
                    alt="SEO Image"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-comp>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import { isEmptyObject, getImage } from '@/utils/Helper';
import FormInputHelp from '@/components/Util/FormInputHelp';
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import { useStore } from 'vuex';
import Toaster from '@/utils/Toaster';

export default defineComponent({
  name: 'SeoMetaAction',
  components: { FormErrorMsg, FormInputHelp, ModalComp },
  emits: ['update:modelValue', 'shown', 'hidden', 'updated'],
  props: {

    modelValue: {
      type: Boolean,
      default: false
    },

    itemToUpdate: {
      type: Object,
      default: () => {
      }
    },

  },
  setup (props, { emit }) {

    const store = useStore();

    const state = reactive({

      showAction: props.modelValue,

      isFormSubmitting: false,
      tagInputField: '',

      isUpdating: false,

      form: {

        link: {
          error: false,
          value: ''
        },

        title: {
          error: false,
          value: ''
        },

        keywords: {
          error: false,
          value: []
        },

        description: {
          error: false,
          value: ''
        },

        copyright: {
          error: false,
          value: ''
        },

        thumbnail: {
          error: false,
          value: '',
          preview: '',
          name: ''
        }
      },

      showKeywordInputForm: false

    });

    const populateAddUpdateForm = (nv) => {

      const isCreating = isEmptyObject(nv);
      state.isUpdating = !isCreating;

      const populateForm = {

        link: {
          error: false,
          value: isCreating ? '' : nv.link
        },

        title: {
          error: false,
          value: isCreating ? '' : nv.title
        },

        keywords: {
          error: false,
          value: isCreating ? [] : nv.keywords
        },

        description: {
          error: false,
          value: isCreating ? '' : nv.description
        },

        copyright: {
          error: false,
          value: isCreating ? '' : nv.copyright
        },

        thumbnail: {
          error: false,
          value: '',
          preview: getImage(nv.thumbnail, 'seo'),
          name: ''
        },
      };

      if (!isCreating) {
        populateForm.id = nv.id;
      }

      state.form = populateForm;

    };

    const handleDataSave = async () => {

      const isFormValid = validateForm();

      if (!isFormValid) {
        Toaster.error({
          message: 'Fix error first'
        })

        return;
      }

      await handleCreateUpdateData();

    };

    const handleCreateUpdateData = async () => {

      state.isFormSubmitting = true;

      const dataToSubmit = new FormData();

      dataToSubmit.append('link', state.form.link.value);
      dataToSubmit.append('title', state.form.title.value);
      dataToSubmit.append('charset', 'utf-8');
      state.form.keywords.value.map((item) => dataToSubmit.append('keywords[]', item));
      dataToSubmit.append('description', state.form.description.value);
      dataToSubmit.append('copyright', state.form.copyright.value);
      dataToSubmit.append('thumbnail', state.form.thumbnail.value);

      try {

        let response = state.isUpdating
          ? await store.dispatch('seo/updateSeoMeta', { data: dataToSubmit, id: state.form.id })
          : await store.dispatch('seo/createSeoMeta', dataToSubmit);

        Toaster.successAlt({
          message: response.data.message || `SEO Meta data ${state.isUpdating ? 'Updated' : 'Created'}`
        })

        fireEvent('update:modelValue', false);
        fireEvent('updated');

      } catch (e) {

        ErrorHelper.mapServerError(e, (err, ex) => {

          state.form.link.error = ex(err.link);
          state.form.title.error = ex(err.title);
          state.form.keywords.error = ex(err.keywords);
          state.form.description.error = ex(err.description);
          state.form.thumbnail.error = ex(err.thumbnail);
          state.form.copyright.error = ex(err.copyright);

        });

        Toaster.error({
          message: e.message || 'There is an error. please try later'
        })

      }
      state.isFormSubmitting = false;
    };

    const validateForm = () => {

      ErrorHelper.checkValidity(validateData);

      const form = state.form;
      let formIsValid = true;

      for (let key in form) {

        if (form[key].error) {
          formIsValid = false;
          break;
        }

      }

      return formIsValid;
    };

    const fireEvent = (event, value) => emit(event, value);

    const handleDeleteTag = (tag) => {
      state.form.keywords.value = state.form.keywords.value.filter((item) => item !== tag);
    };

    const handleAddTag = (event) => {

      // 188 for comma and 13 for enter
      let keyCodesToMatch = [188, 13];

      // check if user pressing comma and enter or not
      if (!keyCodesToMatch.includes(event.which || event.keyCode)) return;

      // check if input field has value
      if (!state.tagInputField) return;

      state.form.keywords.value.push(state.tagInputField.replace(',', ''));
      state.tagInputField = '';
    };

    const handleCcChange = () => {
      console.log(state.form.keywords.value)
    };

    const validationRules = {

      form: {
        link: {
          value: {
            required,
          }
        },

        title: {
          value: {
            required
          }
        },

        keywords: {
          value: {
            required,
          }
        },
        description: {
          value: {
            required,
            minLength: minLength(10)
          }
        }
      }
    };

    const validateData = useVuelidate(validationRules, state, { $autoDirty: true });

    const uploadThumbnail = ref(null);

    const handleFileUpload = () => {

      let file = uploadThumbnail?.value?.files[0];

      if (!file) return;

      state.form.thumbnail.value = file;

      // get user uploaded filename
      state.form.thumbnail.name = file.name;

      // Create url from file
      const fileUrl = URL.createObjectURL(file);

      state.form.thumbnail.preview = fileUrl;

    };

    const triggerClick = () => {
      uploadThumbnail.value.click();
    };

    // Check if validator has any error
    watch(validateData, () => {

      ErrorHelper.getValidationErrors(validateData, (errors) => {

        state.form.link.error = errors['form.link.value'] || false;
        state.form.title.error = errors['form.title.value'] || false;
        state.form.keywords.error = errors['form.keywords.value'] || false;
        state.form.description.error = errors['form.description.value'] || false;

      }, {
        'form.link.value': 'Link',
        'form.title.value': 'Title',
        'form.keywords.value': 'Keywords',
        'form.description.value': 'Description',
      });

    });

    // Update v-model on parent Component
    watch(() => props.modelValue, (nv) => {
      let eventName = nv ? 'shown' : 'hidden';
      state.showAction = nv;
      fireEvent('update:modelValue', nv);
      fireEvent(eventName, nv);

    });

    // Make Link As Slug
    watch(() => state.form.link.value, (nv) => {

      if (!nv) return;

      state.form.link.value = nv.replace(' ', '-');
    });

    // Check if User is Updating or Creating
    watch(() => props.itemToUpdate, (nv) => populateAddUpdateForm(nv));

    return {
      state,
      handleCcChange,
      fireEvent,
      handleDataSave,
      handleDeleteTag,
      handleAddTag,
      uploadThumbnail,
      handleFileUpload,
      triggerClick,
    };

  }
});
</script>

<style lang="scss">
.img-preview {
  max-height: 200px;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
</style>
